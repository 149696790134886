<template>
  <div class="freighth5details">
    <div class="h5-detail">
      <div class="h5-detail-title">
        <div class="start">
          <div>{{ develop }}</div>
          <div>{{ developStation }}</div>
        </div>
        <div class="h5-line"></div>
        <div class="end">
          <div>{{ destination }}</div>
          <div>{{ destinationStation }}</div>
        </div>
      </div>
      <div class="detail-list" v-if="type == 0">
        <div class="railway-item" v-for="(item, index) in list" :key="index">
          <div class="row row-header">
            <div class="label">办理方式</div>
            <div class="value">{{ item.name }}</div>
          </div>
          <div class="row">
            <div class="label">运费含税9%(元/吨)</div>
            <div class="value">{{ item.freightNine }}</div>
          </div>
          <div class="row">
            <div class="label">运费含税13%(元/吨)</div>
            <div class="value">{{ item.freightThirteen }}</div>
          </div>
        </div>
      </div>
      <div class="detail-list" v-if="type == 1">
        <div class="highway-item" v-for="(item, index) in list" :key="index">
          <div class="row">
            <div class="label">始发地</div>
            <div class="value">{{ item.developStation }}</div>
          </div>
          <div class="row">
            <div class="label">目的地</div>
            <div class="value">{{ item.destinationStation }}</div>
          </div>
          <div class="row">
            <div class="label">价格(元/吨 不含税)</div>
            <div class="value">{{ item.amount }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { railwayList, railwayCalcd, highwayCalcd } from "@/api/hy.js";
export default {
  name: "freighth5details",
  data() {
    return {
      destination: "",
      destinationStation: "",
      develop: "",
      developStation: "",
      type: 0,
      list: [],
    };
  },
  created() {
    let type = this.$route.query.type;
    this.destination = this.$route.query.destination;
    this.destinationStation = this.$route.query.destinationStation;
    this.develop = this.$route.query.develop;
    this.developStation = this.$route.query.developStation;
    this.type = type;
    let { destination, destinationStation, develop, developStation } =
      this.$route.query;
    if (type == 0) {
      this.railwayCalcd({
        destination,
        destinationStation,
        develop,
        developStation,
      });
    }
    if (type == 1) {
      this.highwayCalcd({
        destination,
        destinationStation,
        develop,
        developStation,
      });
    }
  },
  methods: {
    railwayCalcd(obj) {
      railwayCalcd(obj).then((res) => {
        if (res.code == 0) {
          this.list = res.data || [];
        }
      });
    },
    highwayCalcd(obj) {
      highwayCalcd(obj).then((res) => {
        if (res.code == 0) {
          this.list = res.data || [];
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.freighth5details {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/h5/bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 24px;
  .h5-detail {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .h5-detail-title {
    height: 160px;
    background: linear-gradient(268deg, #71afeb 0%, #cae3fd 100%);
    border-radius: 12px;
    display: flex;
    align-items: center;
    & > div {
      &:nth-child(1),
      &:nth-child(3) {
        flex: 1;
      }
    }
    .h5-line {
      width: 100px;
      height: 2px;
      background: #222222;
    }
    .start,
    .end {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > div {
        &:nth-child(1) {
          font-size: 28px;
          color: #333;
          font-weight: bold;
        }
        &:last-child {
          font-size: 24px;
          color: #333;
        }
      }
    }
  }
  .detail-list {
    flex: 1;
    margin-top: 24px;
    overflow-y: auto;
    .railway-item,.highway-item {
      background-color: #fff;
      border-radius: 12px;
      overflow: hidden;
      margin-top: 24px;
      &:first-child {
        margin-top: 0;
      }
      .row {
        display: flex;
        align-items: center;
        font-size: 28px;
        justify-content: space-between;
        padding: 30px;
        .label {
          color: #999;
        }
        .value {
          color: #333;
        }
      }
      .row-header {
        background: #f5f6fa;
        border-radius: 12px;
        overflow: hidden;
        .label,
        .value {
          font-size: 30px;
          color: #323232;
        }
      }
    }
  }
}
</style>
